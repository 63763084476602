import React from 'react'

function Footer() {
    return (
        <>
        <footer className="footer-area" style={{backgroundImage: `url(images/footer/footer-bg.png)`}}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-10 col-md-6 col-sm-12">
                        <div className="single-footer-widget">
                            <div className="footer-social-links footer-menu">
                                <span>Stay Connected:</span> 
                                <ul>
                                <li><a href="https://www.facebook.com/profile.php?id=100072036812517" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook" aria-hidden="true"></i> </a></li>
                                <li><a href="https://www.instagram.com/nutrismbygarima/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" aria-hidden="true"></i> </a></li>
                                <li><a href="https://www.linkedin.com/in/garima-singh-rathore-312b7a108/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin" aria-hidden="true"></i> </a></li>
                                </ul>   
                            </div>        
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <p> </p>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                        <div className="single-footer-widget">
                            <div className="footer-bottom">
                                <div className="copyright-text pull-left">
                                    <p>Copyrights © {(new Date()).getFullYear()} <a className="thm-clr" href="/">Nutrism</a> All Right Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <a href="https://wa.me/916287091071?text=Hi" target="_blank" rel="noopener noreferrer">
            <div className="whatsappWidget widgetPosition-Bottom-Right">
                <img src="/images/resources/whatsapp_popup.png" className="whatsappIcon" alt="whatsapp popup"/>
            </div>
        </a>
        
        </>
    )
}

export default Footer
