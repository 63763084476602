import React from 'react';

import programs from '../programs.json';

function Programs() {
    return (
        <section className="services-area" style={{backgroundImage: `url(images/parallax-background/background-services.jpg)`}}>
            <div className="container">
                <div className="sec-title text-center">
                    <div className="title clr-white">Programs</div>
                    <div className="dector thm-bg-clr center"></div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="service-carousel-box owl-carousel owl-theme">
                            { programs.map((program) => {
                                return <div key={program.name} className="single-service-item">
                                    <div className="inner-content">
                                        <div className="title-holder top">
                                            <div className="icon">
                                                <span className="icon-fruit thm-clr"></span>    
                                            </div>
                                            <div className="title">
                                                <h3><a href="/programs">{program.name}</a></h3>
                                                <span>1 Month: &#8377; {program.month1}/- 3 Months: &#8377; {program.month3}/-</span>
                                            </div>   
                                        </div>
                                        <div className="img-holder">
                                            <img src={"images/programs/"+program.image} width="390" height="250" alt={program.name}/>
                                            <div className="overlay-style-one">
                                                <div className="box">
                                                    <div className="content">
                                                        <div className="text-holder">
                                                            <div className="title-holder">
                                                                <div className="icon">
                                                                    <span className="icon-fruit thm-clr"></span>    
                                                                </div>
                                                                <div className="title">
                                                                    <h3><a href="/programs">{program.name}</a></h3>
                                                                    <span>1 Month: &#8377; {program.month1}/- 3 Months: &#8377; {program.month3}/-</span>
                                                                </div>   
                                                            </div>
                                                            <div className="text">
                                                                <p>{program.description.substr(0, 100)}...</p>
                                                                <a href="/programs" className="btn-two"><span className="icon-arrows-4"></span>Read More</a>  
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="bottom-button">
                                            <a href="#" className="btn-two"><span className="icon-arrows-4"></span>Let’s Start</a>    
                                        </div> */}
                                    </div>
                                </div>
                            
                            })}
                            
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="service-bottom-text">
                            <h5 className="text-white">CONSULTATION FEE &#8377; 1000/-</h5>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="service-bottom-button clearfix">
                            <a href="/contact">
                                <div className="icon-box-one">
                                    <span className="icon-two one"></span>    
                                </div>
                                <div className="icon-box-two">
                                    <span className="icon-arrows-3 two"></span>    
                                </div>
                                Request for Consultation
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Programs
