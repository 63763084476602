import React from 'react';

function Consultation() {
    return (
        <section className="consultation-area">
            <div className="consultation-bg">
                <img src="images/pattern/consultation-bg.jpg" alt="Consultation Bg"/>
            </div>
            <div className="container inner-content">
                <div className="row">
                    <div className="col-md-6">
                        <div className="sec-title">
                            <div className="title left">For Consultation</div>
                            <div className="dector thm-bg-clr"></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className="consultation">
                        <div className="address">
                            <p>Nutrism not only supports a healthy way of eating for life but also an appetite <br/>to enjoy life to the full by reaping the benefits of optimum nutrition.</p>
                            <ul>
                                <li><strong>Phone:</strong> <a href="tel:+916287091071" className="link-header">+91-6287091071</a></li>
                                <li><strong>Email:</strong> <a href="mail:garima@nutrism.in" className="link-header">garima@nutrism.in</a></li>
                            </ul>
                        </div>
                            {/* <form className="consultation-form">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="single-box">
                                                    <input type="text" name="form_name" value="" placeholder="Name" required=""/>
                                                </div>
                                                <div className="single-box">
                                                    <input type="text" name="form_phone" value="" placeholder="Phone"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="single-box"> 
                                                    <input type="email" name="form_email" value="" placeholder="Email" required=""/>
                                                </div>
                                                <div className="single-box"> 
                                                    <select className="selectmenu">
                                                        <option selected="selected">Select Program</option>
                                                        { programs.map((program) => {
                                                            return <option value={program.name}>{program.name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="single-box"> 
                                                    <textarea name="form_message" placeholder="Message..." required></textarea> 
                                                    <button type="submit"><span className="icon-arrows-4"></span></button>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>*/}
                        </div> 
                    </div>
                    <div className="col-md-6 img-box">
                        <img src="images/resources/consulation-img.jpg" alt="Consultation Img"/>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default Consultation
