import React from 'react'
import { NavLink, useLocation} from 'react-router-dom'

function Menu(props) {
    const location = useLocation();

    return (
        <div className="header-bottom stricky">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="inner-content clearfix">
                            <div className="header-bottom-left clearfix float-left">

                                <nav className="main-menu clearfix">
                                    <div className="navbar-header clearfix">
                                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                        </button>
                                    </div>
                                    <div className="navbar-collapse collapse clearfix">
                                        <ul className="navigation clearfix">
                                            <li className={location.pathname === '/' ? 'current': ''}><a href="/">Home</a></li>
                                            <li><NavLink to="/about" activeClassName="current">About Garima</NavLink></li>
                                            <li><NavLink to="/programs" activeClassName="current">Programs</NavLink>
                                                { /* <li className="dropdown">
                                                    <ul>
                                                    <li><a href="ser-nutrition.html">Nutrition Strategies</a></li>
                                                    <li><a href="ser-workout.html">Workout Routines</a></li>
                                                    <li><a href="ser-fitness.html">Fitness & Performance</a></li>
                                                    <li><a href="ser-support.html">Support & Motivation</a></li>
                                                    <li><a href="ser-balance.html">Balance Body & Mind</a></li>
                                                    <li><a href="ser-physical.html">Physical Activities</a></li>
                                                </ul>*/ }
                                            </li>
                                            <li><NavLink to="/contact" activeClassName="current">Contact Us</NavLink></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            <div className="header-bottom-right float-right">
                                <span>Stay Connected:</span>
                                <ul className="header-social-links">
                                    <li><a href="https://www.facebook.com/profile.php?id=100072036812517" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook" aria-hidden="true"></i> </a></li>
                                    <li><a href="https://www.instagram.com/nutrismbygarima/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" aria-hidden="true"></i> </a></li>
                                    <li><a href="https://www.linkedin.com/in/garima-singh-rathore-312b7a108/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin" aria-hidden="true"></i> </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Menu
