import React from 'react'

function NotFound() {
    return (
        <section className="not-found-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="not-found-content text-center">
                            <h1>404</h1>
                            <h3>OOPPS! THE PAGE YOU WERE LOOKING FOR, COULDN'T BE FOUND.</h3>
                            <div className="go-home">
                                <a href="/">Back to Home Page</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NotFound
