import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import { BrowserRouter as Router, Route , Switch } from "react-router-dom"; 
import Home from './components/Home';
import About from './components/About';
import Consultation from './components/Consultation';
import ProgramPage from './components/ProgramPage';
import NotFound from './components/NotFound';

function App() {
  return (
    <Router>
    <div className="App">
      <div className="boxed_wrapper">
        <div className="preloader"></div> 
        <Header></Header>
          <Switch>  
            <Route path="/" exact component={Home}></Route>
            <Route path="/about" exact component={About}></Route>
            <Route path="/programs" exact component={ProgramPage}></Route>
            <Route path="/contact" exact component={Consultation}></Route>
            <Route path="" component={NotFound} />
          </Switch>
        <Footer></Footer>
      </div>  
    </div>
    </Router>
  );
}

export default App;
