import React from 'react'
import Menu from './Menu'

function Header() {
    return (
        <header className="header-area">
            <div className="header-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="inner-content clearfix">
                                <div className="header-left float-left">
                                    <div className="logo">
                                        <a href="/">
                                            <img src="images/resources/logo.jpg" alt="Awesome Logo"/>
                                        </a>
                                    </div>
                                </div>
                                <div className="header-middle clearfix float-right">
                                    <ul className="info-box">
                                        <li>
                                            <div className="icon-holder">
                                                <span className="icon-multimedia thm-clr"></span>    
                                            </div>
                                            <div className="title-holder">
                                                <h5>Mail us</h5> 
                                                <p><a href="mail:garima@nutrism.in" className="link-header">garima@nutrism.in</a></p>   
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon-holder">
                                                <span className="fa fa-phone thm-clr"></span>    
                                            </div>
                                            <div className="title-holder">
                                                <h5>Call us</h5> 
                                                <p><a href="tel:+916287091071" className="link-header">+91-6287091071</a></p>   
                                            </div>
                                        </li>   
                                    </ul>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>        
            </div>
            <Menu></Menu>    
        </header>  

    )
}

export default Header
