import React from 'react'

function About() {
    return (
        <section className="welcome-area about-page">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="welcome-right">
                            <div className="video-holder">
                                <img src="images/resources/aboutgarima.png" alt="Garima Rathore"/>
                                
                            </div>              
                        </div>   
                    </div>
                    <div className="col-xl-8">
                        <div className="health-coacher">
                            <div className="text-box">
                                <div className="coacher-name">
                                    <h2>Garima Rathore</h2>
                                    <span className="thm-clr">Clinical Nutritionist</span>
                                </div>
                                <div className="coacher-content">
                                    <p>Garima is a qualified clinical nutritionist, her passion is nourishing from the within. Her vision is to make it simple to learn natural nutrition and the art of healthy living for people, with the end goal of transforming daily practices into healthy habits. Garima’s expertise inculcates of multiple master degrees specialising in Nutraceuticals, Clinical Nutrition and Dietetics. She is here to help you understand what is going on the subsurface of your anatomy and support you through the process of changing your diet with custom made meal plans that will help you get back to living in tune with your body. Through developing self-awareness and analysing the mechanisms of your body, Garima indulges you on a journey to understand the significance of your veritable self. In this process, she is committed to mentoring clients to be their best and to learn how to nourish their body, mind and soul. Her focus is to support you while you reclaim your health and live the best life that you were meant to live.</p>
                                </div>                                
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>    
        </section>
    )
}

export default About
