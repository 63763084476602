import React from 'react';
import programs from '../programs.json';

function ProgramPage() {
    return (
        <section className="welcome-area">
            <div className="container">
            <div className="sec-title text-center">
                <div className="title">Our Programs</div>
                <div className="dector center thm-bg-clr"></div>
            </div>
            { programs.map((program) => {
                return <div className="row" style={{marginBottom:'10px'}}>
                    <div className="col-xl-4">
                        <div className="welcome-right">
                            <div className="video-holder">
                                <img src={"images/programs/"+program.image} alt={program.name}/>
                                
                            </div>              
                        </div>   
                    </div>
                    <div className="col-xl-8">
                        <div className="health-coacher">
                            <div className="text-box">
                                <div className="coacher-name">
                                    <h2>{program.name}</h2>
                                    {/* <span className="thm-clr">Nutritionist</span> */}
                                </div>
                                <div className="coacher-content">
                                    <p>{program.description}</p>

                                    <p><strong>1 Month: &#8377; {program.month1}/- 3 Months: &#8377; {program.month3}/-</strong></p>
                                </div>                                
                            </div>
                        </div> 
                    </div>

                </div> 
                })}
            </div>    
        </section>
    
    )
}

export default ProgramPage
