import React from 'react';
import Programs from './Programs';
import programs from '../programs.json';

function Home() {
    return (<>
    <section className="main-slider main-slider-one">
        <div className="rev_slider_wrapper fullwidthbanner-container"  id="rev_slider_486_1_wrapper" data-source="gallery">
            <div className="rev_slider fullwidthabanner" id="rev_slider_486_1" data-version="5.4.1">
                <ul>
                    <li data-thumb="images/resources/slider.png" data-title="Slide Title">
                    <img alt="" className="rev-slidebg" data-bgfit="auto" data-bgparallax="10" data-bgposition="center" data-bgrepeat="no-repeat" data-no-retina="" src="images/resources/slider.png"/> 

                        <div className="tp-caption tp-resizeme" 
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingtop="[0,0,0,0]"
                        data-responsive_offset="on"
                        data-type="text"
                        data-height="none"
                        data-whitespace="normal"
                        data-width="['800','800','600','400']"
                        data-hoffset="['15','15','15','15']"
                        data-voffset="['-70','-80','-80','-40']"
                        data-x="['left','left','left','left']"
                        data-y="['middle','middle','middle','middle']"
                        data-textalign="['top','top','top','top']"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
                            <div className="slide-content left-slide">
                                <div className="big-title" style={{color:"#35ac46"}}>NUTRISM</div>
                                <div className="title" style={{color:"#35ac46"}}>By Garima Rathore</div>
                            </div>   
                        </div>
                        
                    </li>

                    {/* <li data-description="Slide Description" data-easein="default" data-easeout="default" data-fsmasterspeed="1500" data-fsslotamount="7" data-fstransition="fade" data-hideafterloop="0" data-hideslideonmobile="off" data-index="rs-1688" data-masterspeed="default" data-param1="" data-param10="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-rotate="0" data-saveperformance="off" data-slotamount="default" data-thumb="images/slides/2.jpg" data-title="Slide Title" data-transition="parallaxvertical">
                    <img alt="" className="rev-slidebg" data-bgfit="cover" data-bgparallax="10" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src="images/slides/2.jpg"/> 

                        <div className="tp-caption tp-resizeme text-center" 
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingtop="[0,0,0,0]"
                        data-responsive_offset="on"
                        data-type="text"
                        data-height="none"
                        data-whitespace="normal"
                        data-width="['900','900','800','400']"
                        data-hoffset="['15','15','15','15']"
                        data-voffset="['-70','-80','-80','-40']"
                        data-x="['center','center','center','center']"
                        data-y="['middle','middle','middle','middle']"
                        data-textalign="['top','top','top','top']"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
                            <div className="slide-content">
                                <div className="title">Be Healthy</div>
                                <div className="big-title">Certified Health Coach</div>
                            </div>  
                        </div>
                        <div className="tp-caption tp-resizeme text-center" 
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingtop="[0,0,0,0]"
                        data-responsive_offset="on"
                        data-type="text"
                        data-height="none"
                        data-whitespace="normal"
                        data-width="['700','700','700','400']"
                        data-hoffset="['15','15','15','15']"
                        data-voffset="['25','20','20','80']"
                        data-x="['center','center','center','center']"
                        data-y="['middle','middle','middle','middle']"
                        data-textalign="['top','top','top','top']"
                        data-frames='[{"from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1500,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
                            <div className="slide-content">   
                                <div className="text">We have <span>25+ years</span> experience</div>
                            </div>
                        </div>
                        <div className="tp-caption tp-resizeme text-center" 
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingtop="[0,0,0,0]"
                        data-responsive_offset="on"
                        data-type="text"
                        data-height="none"
                        data-whitespace="normal"
                        data-width="['650','600','500','400']"
                        data-hoffset="['15','15','15','15']"
                        data-voffset="['110','110','110','180']"
                        data-x="['center','center','center','center']"
                        data-y="['middle','middle','middle','middle']"
                        data-textalign="['top','top','top','top']"
                        data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":2500,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
                            <div className="slide-content">
                                <div className="btn-box">
                                    <a href="#" className="btn-one thm-bg-clr">About Us</a>
                                    <a href="#" className="btn-one thm-bg-clr">Services</a>
                                </div>
                            </div>
                        </div>
                    
                    </li>
                    
                    <li data-description="Slide Description" data-easein="default" data-easeout="default" data-fsmasterspeed="1500" data-fsslotamount="7" data-fstransition="fade" data-hideafterloop="0" data-hideslideonmobile="off" data-index="rs-1689" data-masterspeed="default" data-param1="" data-param10="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-rotate="0" data-saveperformance="off" data-slotamount="default" data-thumb="images/slides/3.jpg" data-title="Slide Title" data-transition="parallaxvertical">
                    <img alt="" className="rev-slidebg" data-bgfit="cover" data-bgparallax="10" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src="images/slides/3.jpg"/> 

                        <div className="tp-caption tp-resizeme" 
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingtop="[0,0,0,0]"
                        data-responsive_offset="on"
                        data-type="text"
                        data-height="none"
                        data-whitespace="normal"
                        data-width="['800','800','600','400']"
                        data-hoffset="['15','15','15','15']"
                        data-voffset="['-70','-80','-80','-40']"
                        data-x="['left','left','left','left']"
                        data-y="['middle','middle','middle','middle']"
                        data-textalign="['top','top','top','top']"
                        data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
                            <div className="slide-content left-slide">
                                <div className="title">A natural way of</div>
                                <div className="big-title">Improving health</div>
                            </div>   
                        </div>
                        <div className="tp-caption tp-resizeme" 
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingtop="[0,0,0,0]"
                        data-responsive_offset="on"
                        data-type="text"
                        data-height="none"
                        data-whitespace="normal"
                        data-width="['800','800','600','400']"
                        data-hoffset="['15','15','15','15']"
                        data-voffset="['25','20','20','80']"
                        data-x="['left','left','left','left']"
                        data-y="['middle','middle','middle','middle']"
                        data-textalign="['top','top','top','top']"
                        data-frames='[{"from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1500,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
                            <div className="slide-content left-slide">   
                                <div className="text">With our dedication <span>Support</span></div>
                            </div>
                        </div>
                        <div className="tp-caption tp-resizeme" 
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingtop="[0,0,0,0]"
                        data-responsive_offset="on"
                        data-type="text"
                        data-height="none"
                        data-whitespace="normal"
                        data-width="['800','800','600','400']"
                        data-hoffset="['15','15','15','15']"
                        data-voffset="['110','110','110','180']"
                        data-x="['left','left','left','left']"
                        data-y="['middle','middle','middle','middle']"
                        data-textalign="['top','top','top','top']"
                        data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":2500,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
                            <div className="slide-content left-slide">
                                <div className="btn-box">
                                    <a href="#" className="btn-one thm-bg-clr">Services</a>
                                    <a href="#" className="btn-one thm-bg-clr">Our events</a>
                                </div>
                            </div>
                        </div>
                    
                        
                    </li> */}
                </ul>
            </div>
        </div>
    </section>


    <section className="welcome-area">
        <div className="container">
            <div className="row">
                
                <div className="col-xl-6 col-lg-6">
                    <div className="welcome-image-holder">
                        <img src="images/resources/nutrism-garima-rathore.png" alt="Nutrism-Garima-Rathore"/>
                        
                    </div>   
                </div>
                
                <div className="col-xl-6 col-lg-6">
                    <div className="text-holder">
                        <div className="sec-title">
                            <div className="title left">Nutrism By Garima Rathore</div>
                            <div className="dector thm-bg-clr"></div>
                        </div>
                        <div className="inner-content">
                            <div className="text">
                                <p>
                                Making sustainable lifestyle dietary changes seems straightforward but it can take a lot of patience and will power, especially in the early stages. Working closely with my clients, raising their awareness and helping them to understand the reasoning behind their customised program, helps with the adoption of beneficial practices that can be maintained. What we choose to consume can have a profound impact on our daily lives, health and longevity. At Nutrism, we believe that healthy eating is having a varied diet that meets your nutritional needs, taking into account your preferences, medical history, potential allergies and intolerances as well as some less obvious factors into consideration. Nutrism aims to offer safe and evidence based professional health service offering support, guidance and expertise to motivate clients in becoming the best expert in their own health. Nutrism not only supports a healthy way of eating for life but also an appetite to enjoy life to the full by reaping the benefits of optimum nutrition.
                                </p>
                            </div>   
                        </div>
                    </div> 
                    
                </div>
                
            </div> 
        </div>    
    </section>


    <Programs list={programs}></Programs>

    <section className="tips-area">
        <div className="container">
            <div className="sec-title text-center">
                <div className="title">Tips for Healthy Life</div>
                <div className="dector thm-bg-clr center"></div>
            </div>
            <div className="row">
                
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                    <div className="single-tips-item text-center">
                        {/* <div className="icon-holder">
                            <span className="icon-berry"></span>        
                        </div>
                        <div className="border-box"></div> */}
                        <div className="text-holder">
                            <h3>Walking</h3>
                            <p>Walking for 30 minutes can help in reducing stress and anxiety.</p>
                        </div>
                    </div>  
                </div>
                
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                    <div className="single-tips-item text-center">
                        {/* <div className="icon-holder">
                            <span className="icon-transport"></span>        
                        </div>
                        <div className="border-box"></div> */}
                        <div className="text-holder">
                            <h3>Drinking water</h3>
                            <p>Drinking water is the best way to stay hydrated, as it’s free of calories, sugar, and additives.</p>
                        </div>
                    </div>  
                </div>
                
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                    <div className="single-tips-item text-center">
                        {/* <div className="icon-holder">
                            <span className="icon-food"></span>       
                        </div>
                        <div className="border-box"></div> */}
                        <div className="text-holder">
                            <h3>Don't roast seeds</h3>
                            <p>Don’t roast seeds as it gets oxidised and can lead to acidity</p>
                        </div>
                    </div>  
                </div>
                
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                    <div className="single-tips-item text-center">
                        {/* <div className="icon-holder">
                            <span className="icon-hand"></span>       
                        </div>
                        <div className="border-box"></div> */}
                        <div className="text-holder">
                            <h3>Follow Good Hygiene</h3>
                            <p>Denouncing pleasure praising pain born will completed of the system.</p>
                        </div>
                    </div>  
                </div>
                
            </div>
        </div>
    </section>


    {/* <section className="testimonial-area">
        <div className="container">
            <div className="sec-title">
                <div className="title left">Customer Feedback</div>
                <div className="dector thm-bg-clr"></div>
            </div>
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="client-box owl-carousel owl-theme">

                        <div className="single-testimonial-box style2">
                            <div className="inner-box">
                                <div className="image-holder">
                                    <img src="images/testimonial/1.png" alt="Awesome Image"/>
                                </div>
                                <div className="title-holder">
                                    <h2>Hardley <br/>Richardson</h2>
                                </div>
                            </div>
                            <div className="text-holder">
                                <div className="icon-holder">
                                    <span className="icon-interface-1"></span>
                                </div>
                                <div className="text">
                                    <p>Over the past two years, he was able to slowly and steadily bring that down before having an incredible breakthrough allowed.</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <div className="border-box thm-bg-clr"></div>
                                <div className="title">
                                    <h3 className="thm-clr">Lost 120 Pounds &amp; 2 Sizes </h3>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="single-testimonial-box style2">
                            <div className="inner-box">
                                <div className="image-holder">
                                    <img src="images/testimonial/4.png" alt="Awesome Image"/>
                                </div>
                                <div className="title-holder">
                                    <h2>Melissa <br/>Shredinger</h2>
                                </div>
                            </div>
                            <div className="text-holder">
                                <div className="icon-holder">
                                    <span className="icon-interface-1"></span>
                                </div>
                                <div className="text">
                                    <p>Over the past two years, he was able to slowly and steadily bring that down before having an incredible breakthrough allowed.</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <div className="border-box thm-bg-clr"></div>
                                <div className="title">
                                    <h3 className="thm-clr">Finally Feeling Like Myself</h3>
                                </div>
                            </div>
                        </div>
                        
                    </div>       
                </div>
            </div>
                
        </div>
    </section> */}
    </>
    )
}

export default Home
